
























// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Services ---
import AuthService from '@/services/auth';

@Component
export default class DefaultAccount extends Vue {

  logout() {
    AuthService.logout();  
  }
}
